import { toasterState } from 'Components/common/base/Toaster';
import suppressionListState from 'Stores/suppressionList';
import advSearchState from 'Stores/advSearch';
import commonAppData from 'Stores/commonAppData';
import savedSearchState from 'Stores/savedSearch';
import trialUserOnboardingState from 'Stores/trialUserOnboardingState';
import { makeApi, URLS } from 'Utils/apiUtil';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import * as CONSTANTS from './constants';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import advSearchFiltersState from 'Stores/advSearchFilters';

const { MX_PROFILE_PROPERTIES } = CONSTANTS;

const removeAutoLoginKeyFromUrl = (url = window.location.href) => {
  const newUrl = url.replace(/ak=[a-zA-Z0-9]+&?/, '');

  window.history.replaceState(null, null, newUrl);
};

export const myLeadsList = (isFirstLoad = false) => {
  const config = {
    url: userDetail.userInfo.teamId
      ? `${URLS.myList.list}?teamId=${userDetail.userInfo.teamId}`
      : URLS.myList.list,
    method: 'GET',
  };
  makeApi(config)
    .then((response) => {
      if (!userDetail.myLeadListLoaded) {
        userDetail.myLeadListLoaded = true;
      }
      if (response && response.data) {
        if (response.data.contactList) {
          let updatedList = [];
          updatedList = (
            response.data.contactList?.map((list) => {
              if (!list.id) {
                return { ...list, id: null };
              }
              return list;
            }) || []
          ).concat(response.data.sharedList || []);

          updatedList = updatedList?.sort((list1, list2) => {
            return list2.createdDate - list1.createdDate;
          });

          userDetail.setMyLeadList(updatedList);
        }
        if (response.data?.creatorsName) {
          userDetail.sharedListAuthorData = response.data.creatorsName;
        }
      }
    })
    .catch((error) => {
      if (!userDetail.myLeadListLoaded) {
        userDetail.myLeadListLoaded = true;
      }
      console.log('myLeadsList error ', error);
    });
};

export const getSubscriptionPlans = () => {
  const config = {
    url: URLS.getSubscriptionPlans,
    method: 'GET',
  };
  makeApi(config)
    .then((response) => {
      // console.log('myLeadsList response  ', response);

      if (
        response &&
        response.status &&
        response.status === 200 &&
        response.data
      ) {
        const { list = [] } = response.data;
        userDetail.setSubscriptionPlans(list);
      }
    })
    .catch((error) => {
      console.log('getSubscriptionPlans error ', error);
    });
};

export const getHubspotCalendarLink = () => {
  const config = {
    url: URLS.getHubspotCalendarLink,
    method: 'GET',
  };
  makeApi(config)
    .then((response) => {
      if (response && response.data && response.data.link) {
        userDetail.setHubspotCalendarLink(response.data.link);
      }
    })
    .catch((error) => {
      console.log('getHubspotCalendarLink error ', error);
    });
};

export const getSalesgearUserDetail = async () => {
  try {
    const config = {
      url: URLS?.salesgear?.getUserDetail,
      method: 'GET',
    };
    const res = await makeApi(config);
    if (res?.data) {
      userDetail.setSalesgearUserInfo(res.data);
    }
  } catch (e) {
    console.log('Getting salesgear user detail failed', e);
  }
};

const fetchTeamMemberNames = async (teamId) => {
  const config = {
    url: Utils.generateUrl(URLS.getMembersName, { teamId }),
  };
  const response = await makeApi(config);

  if (response.status === 200) {
    if (Array.isArray(response.data)) {
      userDetail.sharedListAuthorData = response.data;
    }
  }
};

export const loginValidate = async (
  additionalOperation,
  isFirstLoad = false,
) => {
  const pageUrl = window.location.href;
  let autoLoginKey = null;
  if (pageUrl.includes('ak=')) {
    autoLoginKey = Utils.getQueryParamFromUrl('ak', pageUrl);
  }

  const windowUrl = window.location.href;
  let isGetSuppressionListTriggered = false;
  if (
    windowUrl.includes('/advanced-search/') &&
    windowUrl.includes('#search') &&
    isFirstLoad
  ) {
    isGetSuppressionListTriggered = true;
    suppressionListState.getSuppressionList();
  }
  try {
    const config = {
      url:
        URLS.loginValidation +
        (autoLoginKey !== null ? `?ak=${autoLoginKey}` : ''),
      method: 'GET',
    };

    // eslint-disable-next-line no-return-await
    const response = await makeApi(config);

    if (Utils.isProductionEnv()) {
      import('@sentry/react').then((Sentry) => {
        const email = response.data?.member?.email;
        if (Utils.isNotUndefinedNotNull(email)) {
          Sentry.setUser({ email });
        }
      });
    }

    const userObj = {};
    if (response && response.data && response.data !== '') {
      const {
        member,
        team = null,
        memberSubscription = null,
        purchaseLimit = 5000,
        currentPlanDisplayName = null,
        trialOnboardingDetail = null,
        paymentPending = null,
        suppressionListEmailLimit = 25000,
        remainingIndividualLbCredits,
        lbCreditsIndividualLimitFrequency,
        setPassword = false,
        subscriptionPlan,
      } = response && response.data;
      if (member) {
        const {
          emailUpdateHistory,
          secondaryEmailUpdateHistory,
          hubspotEnrichmentSteps,
          searchFilterFlags,
        } = member;
        userObj.email = member.email;
        userObj.lbCreditsIndividualLimitFrequency =
          lbCreditsIndividualLimitFrequency ?? '';
        userObj.remainingIndividualLbCredits =
          remainingIndividualLbCredits !== undefined &&
          remainingIndividualLbCredits !== null
            ? remainingIndividualLbCredits
            : -1;
        userObj.memberId = member.id ? member.id : '';
        userObj.salesgearApiKey =
          // eslint-disable-next-line camelcase
          member?.crmPreferences?.SALESGEAR?.api_key || '';
        userObj.secondaryEmail = member?.secondaryEmail;
        userObj.secondaryEmailStatus = member?.secondaryEmailStatus;

        userObj.secondaryHsEmail = member.trialHubspotData
          ? member.trialHubspotData.email
          : '';
        userObj.emailUpdateHistory = emailUpdateHistory || [];
        userObj.secondaryEmailUpdateHistory = secondaryEmailUpdateHistory || [];
        userObj.isSetPassword = setPassword;
        userObj.hubspotEnrichmentSteps = hubspotEnrichmentSteps;
        userObj.currentSubscriptionPlan = subscriptionPlan;

        if (Utils.isNotUndefinedNotNull(searchFilterFlags)) {
          const dataFromAPI = {
            showContactsWithEmail: Utils.isNotUndefinedNotNull(
              searchFilterFlags.showContactsWithEmail,
            )
              ? searchFilterFlags.showContactsWithEmail
              : true,
            showContactsWithoutEmail: Utils.isNotUndefinedNotNull(
              searchFilterFlags.showContactsWithoutEmail,
            )
              ? searchFilterFlags.showContactsWithoutEmail
              : false,
          };
          advSearchState.checkBoxFilters = {
            ...advSearchState.checkBoxFilters,
            ...dataFromAPI,
          };
          userObj.searchFilterFlags = dataFromAPI;
        } else {
          userObj.searchFilterFlags = {
            showContactsWithEmail: true,
            showContactsWithoutEmail: false,
          };
        }

        if (isFirstLoad) {
          if (trialOnboardingDetail !== null) {
            trialUserOnboardingState.setTrialInfoDetail(trialOnboardingDetail);
          }

          if (memberSubscription !== null) {
            featureFlagsAndPreferencesState.setFeatureFlags(memberSubscription);
            const { price = 0, planType } = memberSubscription;
            const freeTrial = planType === 'TRIAL';
            const enterpriseFreeTrial = planType === 'ENTERPRISE_TRIAL';
            userDetail.setIsFreeTrial(freeTrial);
            userObj.isFreeTrial = freeTrial;
            userDetail.setIsEnterpriseFreeTrial(enterpriseFreeTrial);

            if (!freeTrial) {
              Utils.mixpanelUpdateProfileProperty({
                [MX_PROFILE_PROPERTIES.PLAN_PRICE]: price,
              });
            }

            Utils.mixpanelUpdateProfileProperty({
              [MX_PROFILE_PROPERTIES.ENTERPRISE_TRIAL]: enterpriseFreeTrial,
            });
          }
        }
        userObj.name = member.name || `${member.firstName} ${member.lastName}`;
        userObj.firstName = member.firstName;
        userObj.lastName = member.lastName;
        userObj.apiKey = member.apiKey ? member.apiKey : '';
        userObj.timeZone = member.timeZone ? member.timeZone : '';

        userObj.crmPreferences = member.crmPreferences
          ? member.crmPreferences
          : null;

        userObj.trialUsed = member.trialUsed ? member.trialUsed : false;
        userObj.type = member.type !== null ? member.type : 0;

        userObj.userStatus =
          member.status !== undefined && member.status !== null
            ? member.status
            : 2;

        userObj.pageSource = member.pageSource;
        userObj.esSearchEnabled =
          member?.userPreferences?.IS_ES_SEARCH_ENABLED || false;

        featureFlagsAndPreferencesState.setPreferences(member?.userPreferences);

        userObj.role =
          member.role !== undefined && member.role !== null
            ? member.role === 90
              ? 'ROLE_DATA_ADMIN'
              : member.role === 100 || member.role === 120
                ? 'ROLE_ADMIN'
                : 'ROLE_MEMBER'
            : 'ROLE_MEMBER';

        userObj.creationTimeStamp =
          member.create_ts !== undefined && member.create_ts !== null
            ? member.create_ts
            : '';
      }

      userObj.paymentPending = paymentPending;
      userObj.userLoggedIn = true;

      if (team !== null) {
        userObj.noOfSeatsEligible = memberSubscription?.numberOfSeats;
        userObj.noOfSeatsUsed = team.noOfSeats;
        userObj.teamCRMPreferences = team?.crmAccount || {};
        userObj.team = team;
      }
      const { noOfSeatsEligible = 0, noOfSeatsUsed = 0 } = userObj;

      Utils.mixpanelUpdateProfileProperty({
        [MX_PROFILE_PROPERTIES.SEAT_ELIGIBLE]: noOfSeatsEligible,
        [MX_PROFILE_PROPERTIES.SEAT_USED]: noOfSeatsUsed,
      });

      userObj.userPlanMX =
        currentPlanDisplayName !== undefined && currentPlanDisplayName !== null
          ? currentPlanDisplayName
          : 'Free Forever';
      userObj.currentPlan = response.data.currentPlan
        ? response.data.currentPlan
        : '';
      userObj.currentPlanDisplayName = response.data.currentPlanDisplayName
        ? response.data.currentPlanDisplayName
        : '';
      userObj.isEnterpriseAdmin = response.data.enterpriseAdmin
        ? response.data.enterpriseAdmin
        : false;
      userObj.teamAdminEmail = response.data.teamAdminEmail
        ? response.data.teamAdminEmail
        : '';
      const {
        lbCredits: teamLbCredits,
        phoneCreditsRemaining: teamPhoneCreditsRemaining,
        enrichmentCreditsRemaining: teamEnrichmentCreditsRemaining,
      } = team ?? {};
      const {
        lbCredits: memberLbCredits,
        phoneCreditsRemaining: memberPhoneCreditsRemaining,
        enrichmentCredits: memberEnrichmentCredits,
      } = member;
      const lbCredits = teamLbCredits ?? memberLbCredits ?? 0;
      userObj.teamId = team ? team.id : null;
      userObj.enrichmentCreditsRemaining =
        teamEnrichmentCreditsRemaining ?? memberEnrichmentCredits ?? 0;

      userObj.lbCredits = lbCredits;
      userObj.phoneCreditsRemaining =
        teamPhoneCreditsRemaining ?? memberPhoneCreditsRemaining ?? 0;
      if (userDetail.isFreeTrial) {
        Utils.mixpanelUpdateProfileProperty({
          [MX_PROFILE_PROPERTIES.CREDITS_REMAINING]: lbCredits,
        });
      }

      userObj.purchaseLimit = purchaseLimit;
      userObj.suppressionListEmailLimit = suppressionListEmailLimit;
      userObj.memberSubscription = memberSubscription;
      userObj.source = member.source;
      userObj.mixpanelData = member.mixpanelData;

      if (userDetail.isFreeTrial && trialOnboardingDetail !== null) {
        trialUserOnboardingState.handleOptionalFlows(
          userObj,
          trialOnboardingDetail,
        );
      }

      userDetail.setUserInfo(userObj);
      userDetail.setInitialLoad(true);
      if (autoLoginKey !== null) {
        setTimeout(() => {
          removeAutoLoginKeyFromUrl();

          if (additionalOperation) {
            myLeadsList(isFirstLoad);
          }
        }, 500);
      } else if (additionalOperation) {
        myLeadsList(isFirstLoad);
      }

      if (isFirstLoad) {
        savedSearchState.getSavedSearch('contact');
        savedSearchState.getSavedSearch('company');
        getSubscriptionPlans();
        getHubspotCalendarLink();
        if (team !== null) {
          fetchTeamMemberNames(team.id);
        }
        // showing funding and tech data in advanced search and my leads
        advSearchFiltersState.getFundingAndTechData();
        commonAppData.getAndSetIndustryFullData();
        if (!isGetSuppressionListTriggered) {
          suppressionListState.getSuppressionList();
        }
        if (userObj.salesgearApiKey) {
          getSalesgearUserDetail();
        }
      }

      // g2 popup logic
      Utils.checkAndShowG2Popup();

      if (isFirstLoad) {
        return response;
      }
      // return true;
    }
    return false;
  } catch (error) {
    console.log('loginValidate error ', error);
  }
};

export const logout = () => {
  const config = {
    url: URLS.logout,
    method: 'POST',
  };
  makeApi(config, false)
    .then(() => {
      Utils.mixpanelReset();
      Utils.removeLocalStorage('companySerPostData');
      Utils.removeLocalStorage('searchJson');
      Utils.deleteCookie('seo_contact_url');
      Utils.deleteCookie('seo_search');
      document.cookie = 'li=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.href = CONSTANTS.WEB_URL;
    })
    .catch((error) => {
      console.log('logout error ', error);
    });
};

export const updatePaidUserWelcomePopupShown = (subscriptionId) => {
  const config = {
    url: URLS.updatePaidUserWelcomePopupShown,
    method: 'POST',
    data: {
      subscriptionId,
    },
  };
  makeApi(config, false);
};

export const resendActivationLink = (email) => {
  const config = {
    url: URLS.resendEmailConfirmation,
    method: 'POST',
    data: {
      email,
    },
  };
  makeApi(config)
    .then((response) => {
      if (response && response.data !== undefined && response.data === 'true') {
        toasterState.setToastMsg('Activation link sent successfully.');
      }
    })
    .catch((error) => {
      console.log('resend Activation Link error ', error);
    });
};
