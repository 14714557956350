import React from 'react';
import { observer } from 'mobx-react';
import G2Logo from 'Assets/png/g2-logo.png';
import CloseIcon from 'Assets/svg/close-material.svg';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

function G2ReviewPopup() {
  const updateLocalStorageData = () => {
    const previousData = Utils.getLocalStorage('g2');
    const { lastShown, count } = previousData;
    Utils.setLocalStorage('g2', {
      lastShown,
      count,
      reviewed: true,
    });
    userDetail.setShowG2Popup(false);
    Utils.mixpanelTrack(CONSTANTS.G2_EVENTS.G2_LINK_CLICKED, {});
  };

  return (
    userDetail.showG2Popup && (
      <div className="g2-review-popup">
        <CloseIcon
          className="close-icon"
          onClick={() => {
            userDetail.setShowG2Popup(false);
          }}
        />
        <div className="left-section">
          <div className="title">Hi {userDetail.userInfo.name},</div>
          <div className="message">
            Great start! Would you like an extra 20 free credits by reviewing us
            on G2?
          </div>
          <a
            onClick={updateLocalStorageData}
            href={CONSTANTS.EXTERNAL_LINKS.G2_REVIEW}
            target="_blank"
            className="button link"
            rel="noopener noreferrer"
          >
            Yes, Take me to G2
          </a>
          <span className="italic-text">
            <i>*Contact support to avail credits</i>
          </span>
        </div>

        <div className="image-wrapper">
          <img src={G2Logo} alt="G2 logo" className="g2-logo" />
        </div>
      </div>
    )
  );
}

export default observer(G2ReviewPopup);
