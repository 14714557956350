/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { observer } from 'mobx-react';
import ContactEmailIcon from 'Assets/svg/contact-email.svg';
import CopyIcon from 'Assets/svg/copy.svg';
import InfoIcon from 'Assets/svg/info-icon.svg';
import Button from 'Components/common/base/Button';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import advSearchState from 'Stores/advSearch';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import ContactPhone from '../ContactPhone';
import { toasterState } from '../../base/Toaster';
import LoaderWithTooltip from '../LoaderWithTooltip';
import './styles.scss';

function ContactEmailPhone(props) {
  const {
    contactId,
    email = '',
    phoneNumberFromVendor = null,
    isDownloaded = false,
    pageName = 'advsearch',
    purchaseContactFunc = null,
    isEmailValid = true,
    purchaseMobile,
    showFindEmail,
    status,
    zbStatus,
    expectedStatus,
  } = props;

  const isOwned = isDownloaded && email?.indexOf('*') === -1;

  const copyContactInfo = (e, isDownload, type) => {
    const str = e.currentTarget.getAttribute('data-value');
    e.preventDefault();
    if (isDownload) {
      Utils.copyToClipBoard(str);
      if (pageName === 'advsearch') {
        Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_COPY_CLICKED, { type });
      } else if (pageName === 'myleads') {
        Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_COPY_CLICKED, { type });
      }

      toasterState.setToastMsg('Copied', CONSTANTS.TOAST_MSG_TYPES.SUCCESS);
    }
  };

  const purchaseContactIfNotOwned = () => {
    if (!isOwned && purchaseContactFunc !== null) {
      purchaseContactFunc(contactId);
    }
  };

  return (
    <div className="contact-info">
      {status === 'DEAD' ? (
        <div className="email-inactive">
          <InfoIcon />
          <span>Email is inactive</span>
        </div>
      ) : (
        showFindEmail &&
        (email !== null &&
        email !== undefined &&
        email !== '' &&
        (isEmailValid || isEmailValid === null) ? (
          <div className={`contact-email${isOwned ? ' owned' : ''}`}>
            <div className="icon-wrapper">
              <ContactEmailIcon className="info-icon" />
              {(zbStatus || expectedStatus) && (
                <ToolTipWithIcon
                  variant="dark"
                  wrapperClassName="email-status-t-wrap"
                  Icon={
                    <div className="deliverability-status-wrapper">
                      <span
                        className={`deliverability-status ${(zbStatus || expectedStatus)?.toLowerCase()}`}
                      />
                    </div>
                  }
                >
                  {zbStatus
                    ? Utils.getValidationStatusTextForValue(zbStatus)
                    : expectedStatus}
                </ToolTipWithIcon>
              )}
            </div>
            <div className="contact-email-copy-wrap">
              <div
                className={`info-detail show-ellipsis ${
                  isOwned ? 'default-cursor' : ''
                }`}
                title={email}
                onClick={purchaseContactIfNotOwned}
              >
                {email}
              </div>
            </div>
            {isOwned && (
              <CopyIcon
                className="copy-icon"
                onClick={(e) => {
                  copyContactInfo(e, isOwned, 'email');
                }}
                data-value={email}
              />
            )}
            {showFindEmail &&
              email?.startsWith('*') &&
              !advSearchState.singlePurchaseLoaderIds.includes(contactId) && (
                <Button
                  variant="light"
                  buttonProps={{ text: 'View' }}
                  onClick={purchaseContactIfNotOwned}
                />
              )}
            {advSearchState.singlePurchaseType === 'EMAIL' && (
              <LoaderWithTooltip contactId={contactId} />
            )}
          </div>
        ) : (
          <div className="email-verification-message">
            <ContactEmailIcon className="info-icon" />
            <span
              className="alert-message"
              title="Sorry, we couldn't verify this email"
            >
              Sorry, we couldn't verify this email
            </span>
          </div>
        ))
      )}

      <ContactPhone
        pageName="advsearch"
        contactId={contactId}
        phone={phoneNumberFromVendor}
        purchaseMobile={purchaseMobile}
      />
    </div>
  );
}

export default observer(ContactEmailPhone);
