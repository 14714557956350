import React from 'react';
import { format } from 'date-fns';
import EmailValidationStatusColor from 'Components/common/base/EmailValidationStatusColor';
import './styles.scss';
import Utils from 'Utils/utils';

function EmailValidationStatus(props) {
  const { validationDate, zbStatus } = props;

  const textToShow = Utils.getValidationStatusTextForValue(zbStatus);

  return (
    <div className="email-validation-status">
      {textToShow ? (
        <>
          <div className="validation-status">
            <EmailValidationStatusColor zbStatus={zbStatus} />
            <div className="status-text">{textToShow}</div>
          </div>
          <div className="validation-date">
            {validationDate ? format(validationDate, 'dd-MMM-yyyy') : ''}
          </div>
        </>
      ) : (
        'N/A'
      )}
    </div>
  );
}

export default EmailValidationStatus;
