import { observable, action, computed } from 'mobx';

const defaultFlags = {
  csvExportEnabled: false,
  savedSearchEnabled: false,
  inclusionListEnabled: false,
  practiceAreaEnabled: false,
  crmExportEnabled: false,
  limitContactPerCompanyEnabled: false,
  usageAnalyticsBasicEnabled: false,
  suppressionListEnabled: false,
  fundingFilterEnabled: false,
  savedSearchJobChangeAlertEnabled: false,
  growthFilterEnabled: false,
  teamManagementEnabled: false,
  usageAnalyticsAdvancedEnabled: false,
  apiEnabled: false,
  euContactsEnabled: false,
  workflowEnabled: false,
};

class FeatureFlagsAndPreferencesState {
  @observable accessor flags = defaultFlags;

  @observable accessor preferences = {};

  @action
  setFeatureFlags(subscription) {
    if (subscription !== null && subscription !== undefined) {
      this.flags = Object.keys(defaultFlags).reduce((output, input) => {
        return { ...output, [input]: subscription[input] ?? false };
      }, {});
    }
  }

  @action
  setPreferences(preferences) {
    this.preferences = preferences;
  }

  @computed
  get enrollmentAPILogEnabled() {
    return this.preferences.WORKFLOW_ENROLLMENT_API_LOG;
  }

  isCSVExportEnabled() {
    return this.flags.csvExportEnabled;
  }

  isSavedSearchEnabled() {
    return this.flags.savedSearchEnabled;
  }

  isCRMExportEnabled() {
    return this.flags.crmExportEnabled;
  }

  isSuppressionListEnabled() {
    return this.flags.suppressionListEnabled;
  }

  isJobAlertsEnabled() {
    return this.flags.savedSearchJobChangeAlertEnabled;
  }

  isEUSearchEnabled() {
    return this.flags.euContactsEnabled;
  }

  isTeamManageEnabled() {
    return this.flags.teamManagementEnabled;
  }

  isBasicReportingEnabled() {
    return this.flags.usageAnalyticsBasicEnabled;
  }

  isGrowthFilterEnabled() {
    return this.flags.growthFilterEnabled;
  }

  isAdvancedReportingEnabled() {
    return this.flags.usageAnalyticsAdvancedEnabled;
  }

  isFundingFilterEnabled() {
    return this.flags.fundingFilterEnabled;
  }

  isWorkflowEnabled() {
    return this.flags.workflowEnabled;
  }
}

const featureFlagsAndPreferencesState = new FeatureFlagsAndPreferencesState();

export default featureFlagsAndPreferencesState;
