/* eslint-disable class-methods-use-this */
import { observable, action, computed } from 'mobx';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';

const userObj = {
  name: null,
  email: null,
  lbCredits: null,
  currentPlan: null,
  crmPreferences: null,
  timeZone: null,
  apiKey: null,
  memberId: null,
  trialUsed: false,
  userLoggedIn: false,
  paymentPending: false,
  salesgearApiKey: '',
  phone: null,
  secondaryEmail: null,
  secondaryEmailStatus: null,
  emailUpdateHistory: [],
  secondaryEmailUpdateHistory: [],
  source: null,
  isEnterpriseAdmin: false,
  teamCRMPreferences: {},
};

export const salesgearUserObj = {
  apiKey: null,
  email: null,
  emailPreferences: null,
  firstName: null,
  lastName: null,
  memberId: null,
  phone: null,
  role: null,
  status: null,
  title: null,
};

const userLimit = {
  limit: 12,
};

class UserDetail {
  @observable accessor userInfo = { ...userObj, ...userLimit };

  @observable accessor onFirstLoad = false;

  @observable accessor myLeadList = [];

  @observable accessor myLeadListWithShared = [];

  @observable accessor myLeadListLoaded = false;

  @observable accessor isFreeTrial = false;

  @observable accessor isEnterpriseFreeTrial = false;

  @observable accessor showNotificationType = '';

  @observable accessor advCurrentTab = '';

  @observable accessor salesgearUserInfo = salesgearUserObj;

  @observable accessor hubspotCalendarLink =
    CONSTANTS.EXTERNAL_LINKS.HUBSPOT_DEFAULT_CALENDAR_LINK;

  @observable accessor subscriptionPlans = [];

  @observable accessor showActivateAccountPage = false;

  @observable accessor showG2Popup = false;

  @observable accessor showUpdateUserEmailPopup = null;

  @observable accessor showPasswordSetupPage = false;

  @observable accessor showUpgradeCreditPopup = false;

  @observable accessor sharedListAuthorData = {};

  @action
  setShowUpgradeCreditPopup = (value) => {
    this.showUpgradeCreditPopup = value;
  };

  @action setMyLeadList = (list) => {
    this.myLeadList = list;
  };

  @action setMyListsWithShared = (list) => {
    this.myLeadListWithShared = list;
  };

  @action
  setShowPasswordSetupPage = (value) => {
    this.showPasswordSetupPage = value;
  };

  @action
  setShowUpdateUserEmailPopup = (value = null) => {
    this.showUpdateUserEmailPopup = value;
  };

  @action
  setShowG2Popup = (value) => {
    this.showG2Popup = value;
  };

  @action
  setShowActivateAccountPage = (val) => {
    this.showActivateAccountPage = val;
  };

  @action
  setShowNotificationType = (val = '') => {
    this.showNotificationType = val;
  };

  @action
  setSubscriptionPlans = (val) => {
    this.subscriptionPlans = val;
  };

  @action
  setCurrentTab(value = '') {
    if (value !== '' && this.advCurrentTab !== value) {
      Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_LEAD_BUILDER_VIEWD, {
        activeTab: value,
      });
    }
    this.advCurrentTab = value;
  }

  @action
  setInitialLoad = (val = true) => {
    this.onFirstLoad = val;
  };

  @action
  setIsFreeTrial = (val = false) => {
    this.isFreeTrial = val;
  };

  @action
  setIsEnterpriseFreeTrial = (val = false) => {
    this.isEnterpriseFreeTrial = val;
  };

  @action
  setUserInfo(obj = userObj) {
    this.userInfo = { ...obj, ...userLimit };
  }

  @action
  updateUserInfo(obj) {
    this.userInfo = { ...this.userInfo, ...obj };
  }

  @action
  addNewListToMyList = (props) => {
    const { id, name, count = 0, isSharedList = false } = props;
    if (id && name) {
      const date = new Date();
      const newObj = {
        id,
        name,
        count,
        lastPurchasedDate: null,
        createdDate: date,
        isSharedList,
        memberId: this.userInfo.memberId,
      };
      const tempList = Utils.copyObjectWithoutReference(this.myLeadList);
      tempList.splice(1, 0, newObj);

      this.myLeadList = tempList;
    }
  };

  @action
  updateMyleadsListCount(obj = {}) {
    const myLeads = Utils.copyObjectWithoutReference(this.myLeadList);
    if (myLeads.length > 0) {
      const newMyLeads = myLeads.map((item) => {
        if (Object.prototype.hasOwnProperty.call(obj, item.id)) {
          const count = obj[item.id];
          return { ...item, count };
        }
        return item;
      });
      this.myLeadList = newMyLeads;
    }
  }

  @action
  setSalesgearUserInfo = (value) => {
    this.salesgearUserInfo = { ...this.salesgearUserInfo, ...value };
  };

  @action
  setHubspotCalendarLink(value = null) {
    if (value) {
      this.hubspotCalendarLink = value;
    }
  }
}

const userDetail = new UserDetail();

export default userDetail;
