import { action, computed, observable } from 'mobx';
import { toasterState } from 'Components/common/base/Toaster';
import { loginValidate } from 'Utils/commonApi';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import userDetail, { salesgearUserObj } from './userDetail';
import { monthNames, TOAST_MSG_TYPES } from '../utils/constants';

class Profile {
  @observable accessor isLoading = false;

  @observable accessor savedCardData = '';

  @observable accessor teamMembers = [];

  @observable accessor teamDetails = {};

  @observable accessor usageAnalyticsData = { data: [] };

  @observable accessor APIUsageAnalyticsData = {
    search: [],
    purchase: [],
    enrich: [],
  };

  @observable accessor apiUsageLoading = false;

  @observable accessor isGetTeamMemberLoading = false;

  @observable accessor teamMemberApiLoading = false;

  @computed
  get apiUsedForChart() {
    const apiUsed = [];
    if (userDetail.userInfo.memberSubscription?.apiSearchCredits > 0) {
      apiUsed.push({ key: 'search', title: 'Prospect API - Search Contacts' });
    }

    if (userDetail.userInfo.memberSubscription?.apiPurchaseCredits > 0) {
      apiUsed.push({
        key: 'purchase',
        title: 'Prospect API - Purchase Contacts',
      });
    }

    if (userDetail.userInfo.memberSubscription?.apiEnrichCredits > 0) {
      apiUsed.push({ key: 'enrich', title: 'Enrich API' });
    }

    return apiUsed;
  }

  @action
  setLoading(value) {
    this.isLoading = value;
  }

  updateName = async (payload) => {
    const config = {
      url: URLS.updateProfileData,
      method: 'POST',
      data: payload,
    };
    this.setLoading(true);
    makeApi(config)
      .then(async (response) => {
        if (response && response.data === true) {
          await loginValidate(false);
          toasterState.setToastMsg(
            'Name has been updated successfully',
            TOAST_MSG_TYPES.SUCCESS,
          );
        } else {
          toasterState.setToastMsg(
            'Something went wrong. Please try again later',
          );
        }
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.log('User Profile Data error ', error);
      });
  };

  updateTimeZone = (timezone) => {
    const lastParenthesis = timezone.lastIndexOf(')') + 1;
    const firstParenthesis = timezone.lastIndexOf('(');
    const zoneValue = timezone.slice(firstParenthesis, lastParenthesis);
    const zoneName = timezone.split(zoneValue).pop();
    const config = {
      url: URLS.updateMemberTimeZone ? URLS.updateMemberTimeZone : '',
      method: 'POST',
      data: {
        zone_name: zoneName.trim(),
        zone_value: zoneValue,
      },
    };
    this.setLoading(true);
    makeApi(config)
      .then(async (response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          await loginValidate(false);
          this.setLoading(false);
          toasterState.setToastMsg(
            'Timezone has been updated successfully',
            TOAST_MSG_TYPES.SUCCESS,
          );
        } else {
          this.setLoading(false);
          toasterState.setToastMsg(
            'Something went wrong. Please try again later',
          );
        }
      })
      .catch((error) => {
        this.setLoading(false);
        console.log('User Profile Data error ', error);
      });
  };

  updatePassword = (values, callBack) => {
    const { memberId } = userDetail.userInfo;
    const { newPassword, currentPassword } = values;
    const config = {
      url: URLS.updatePassword ? URLS.updatePassword : '',
      method: 'POST',
      params: {
        memberId: memberId || '',
        currentPassword,
        newPassword,
      },
    };
    makeApi(config)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          loginValidate(false);
          callBack();
          toasterState.setToastMsg(
            'Password has been updated successfully',
            TOAST_MSG_TYPES.SUCCESS,
          );
        } else {
          callBack();
          toasterState.setToastMsg(
            'Something went wrong. Please try again later',
          );
        }
      })
      .catch((error) => {
        console.log('User Profile Data error ', error);
      });
  };

  checkCurrentPassword = async (value) => {
    const config = {
      url: URLS.checkCurrentPassword ? URLS.checkCurrentPassword : '',
      method: 'POST',
      params: { userPassword: value },
    };
    const response = await makeApi(config);

    return !(
      response &&
      response.status &&
      response.status === 200 &&
      response.data
    );
  };

  getTheRecurringEmails = async () => {
    this.setLoading(true);
    const config = {
      url: URLS?.userAnalytics?.emailReportForRecurring,
      method: 'GET',
    };
    const response = await makeApi(config);
    this.setLoading(false);
    return response;
  };

  getRecurringDataById = async (key) => {
    this.setLoading(true);
    try {
      const targetURL = Utils.generateUrl(
        URLS?.userAnalytics?.deleteRecurringMails,
        { mailId: key },
      );

      const config = {
        url: targetURL,
        method: 'GET',
      };
      const res = await makeApi(config);
      this.setLoading(false);
      return res;
    } catch (e) {
      this.setLoading(false);
      console.log('get recurring email by id failed', e);
    }
  };

  createRecurringEmail = async (appData) => {
    try {
      const config = {
        url: URLS?.userAnalytics?.emailReportForRecurring,
        method: 'POST',
        data: appData,
      };
      const res = await makeApi(config);

      return res;
    } catch (e) {
      console.log('create recurring email failed', e);
    }
  };

  updateRecurringEmail = async (appData) => {
    try {
      const config = {
        url: URLS?.userAnalytics?.emailReportForRecurring,
        method: 'PUT',
        data: appData,
      };
      const res = await makeApi(config);
      return res;
    } catch (e) {
      console.log('Update recurring email failed', e);
    }
  };

  deleteRecurringMail = async (key) => {
    this.setLoading(true);
    try {
      const targetURL = Utils.generateUrl(
        URLS?.userAnalytics?.deleteRecurringMails,
        { mailId: key },
      );

      const config = {
        url: targetURL,
        method: 'DELETE',
      };
      const res = await makeApi(config);
      this.setLoading(false);
      return res;
    } catch (e) {
      this.setLoading(false);
      console.log('delete recurring email schedule failed', e);
    }
  };

  connectToSalesgear = async (apiKey) => {
    let isSuccess = false;
    try {
      this.setLoading(true);
      const config = {
        url: URLS?.salesgear?.authenticate,
        method: 'POST',
        data: { apiKey },
      };
      const res = await makeApi(config);
      if (res?.data) {
        userDetail.setUserInfo({
          ...userDetail.userInfo,
          salesgearApiKey: apiKey,
        });
        userDetail.setSalesgearUserInfo(res.data);
        toasterState.setToastMsg(
          'Successfully connected to Salesgear',
          'success',
        );
        isSuccess = true;
      } else {
        toasterState.setToastMsg(
          'Unable to valid user account - please check your APIKEY.',
          'failure',
        );
        isSuccess = false;
      }

      this.setLoading(false);
      return isSuccess;
    } catch (e) {
      console.log('Connect with salesgear Apikey failed', e);
      this.setLoading(false);
      return isSuccess;
    }
  };

  deleteSalesgearAPIKey = async () => {
    let isSuccess = false;
    try {
      const config = {
        url: URLS?.salesgear?.authenticate,
        method: 'DELETE',
      };
      const res = await makeApi(config);
      if (res?.data) {
        toasterState.setToastMsg('Disconnected successfully', 'success');
        userDetail.setSalesgearUserInfo(salesgearUserObj);
        userDetail.setUserInfo({
          ...userDetail.userInfo,
          salesgearApiKey: '',
        });
        isSuccess = true;
      } else {
        toasterState.setToastMsg('Disconnect failed', 'failure');
        isSuccess = false;
      }
      return isSuccess;
    } catch (e) {
      console.log('delete salesgear api key failed', e);
      return isSuccess;
    }
  };

  @action
  getSavedCard = async () => {
    const config = {
      url: URLS.getSavedCard ? URLS.getSavedCard : '',
      method: 'POST',
    };
    const response = await makeApi(config);

    if (
      response &&
      response.status &&
      response.status === 200 &&
      response.data
    ) {
      this.savedCardData = response.data;
    }
  };

  fetchTeamMembers = async () => {
    const config = {
      url: URLS.team,
      method: 'GET',
    };
    const response = await makeApi(config);
    return response;
  };

  getTeamDetails = async (data = { loader: true }) => {
    const { loader } = data;
    if (loader) {
      this.isGetTeamMemberLoading = true;
    }
    const response = await this.fetchTeamMembers();
    if (response?.status === 200) {
      if (
        response.data &&
        response.data.members &&
        response.data.members.length
      ) {
        this.teamMembers = response.data.members
          .filter((member) => {
            return member.status !== 'INACTIVE' && member.status !== 'INVITED';
          })
          .sort((member1, member2) => {
            return (
              member2.lbCreditsUsedInBillingCycle -
              member1.lbCreditsUsedInBillingCycle
            );
          })
          .concat(
            response.data.members.filter((member) => {
              return member.status === 'INVITED';
            }),
            response.data.members
              .filter((member) => {
                return member.status === 'INACTIVE';
              })
              .sort((member1, member2) => {
                return (
                  member1.lbCreditsUsedInBillingCycle -
                  member2.lbCreditsUsedInBillingCycle
                );
              }),
          );
      }
    }
    this.teamDetails = response.data?.teamDetails;
    if (loader) {
      this.isGetTeamMemberLoading = false;
    }
  };

  addUser = async (values) => {
    this.teamMemberApiLoading = true;
    const postData = {};
    postData.email = values.email ? values.email : '';
    postData.firstName = values.firstName ? values.firstName : '';
    postData.lastName = values.lastName ? values.lastName : '';
    if (values.limit !== '') {
      const credits = parseInt(values.credits);
      if (values.limit === 'YEARLY') {
        postData.lbCreditYearlyLimit = credits;
      } else {
        postData.lbCreditMonthlyLimit = credits;
      }
    }

    postData.status = 'ACTIVE';
    const config = {
      url: URLS.team,
      method: 'POST',
      data: postData,
    };
    const response = await makeApi(config);
    this.teamMemberApiLoading = false;
    if (response) {
      let msgStr = '';
      if (response.status === 200) {
        loginValidate(false);
        this.getTeamDetails({ loader: false });
        msgStr = `We have sent an Invitation email to ${values.email}`;
      } else {
        msgStr = response.response?.data?.responseMsg;
      }
      if (msgStr !== '') {
        toasterState.setToastMsg(msgStr, TOAST_MSG_TYPES.INFO);
      }
    } else if (
      response.response &&
      response.response.status &&
      response.response.status === 500
    ) {
      toasterState.setToastMsg(
        'Sorry, something went wrong. Please try again or Contact <a href="mailto:support@adapt.io" target="_blank">support@adapt.io</a>',
      );
    }
  };

  editUser = async (data) => {
    this.teamMemberApiLoading = true;

    const requestBody = {
      email: data.email,
    };
    if (data.limit !== '') {
      const credits = parseInt(data.credits);
      if (data.limit === 'YEARLY') {
        requestBody.lbCreditYearlyLimit = credits;
        requestBody.lbCreditMonthlyLimit = null;
      } else {
        requestBody.lbCreditMonthlyLimit = credits;
        requestBody.lbCreditYearlyLimit = null;
      }
    } else {
      requestBody.lbCreditMonthlyLimit = null;
      requestBody.lbCreditYearlyLimit = null;
    }
    const config = {
      url: URLS.team,
      method: 'PATCH',
      data: requestBody,
    };

    const response = await makeApi(config);
    this.teamMemberApiLoading = false;
    if (response.data?.result?.toLowerCase() === 'success') {
      if (data.memberId === userDetail.userInfo.memberId) {
        loginValidate(false);
      }
      toasterState.setToastMsg(
        'Credit limit data updated successfully',
        'success',
      );
      this.getTeamDetails({ loader: false });
    } else {
      toasterState.setToastMsg(
        'Setting credit limit failed, please try again',
        'failure',
      );
    }
  };

  removeUser = ({ id, email }) => {
    this.teamMemberApiLoading = true;
    const config = {
      url: `${URLS.team}?teamMemberIdToBlock=${id}`,
      method: 'DELETE',
    };
    makeApi(config)
      .then((response) => {
        if (response.data?.result?.toLowerCase() === 'success') {
          loginValidate(false);
          this.getTeamDetails({ loader: false });
          toasterState.setToastMsg(
            `Removed the user ${email} from the team!`,
            TOAST_MSG_TYPES.SUCCESS,
          );
        }
        this.teamMemberApiLoading = false;
      })
      .catch((error) => {
        this.teamMemberApiLoading = false;
        console.log('Get Team Details Error ', error);
      });
  };

  getParsedMonthRange = (range) => {
    return {
      startMonth: monthNames.indexOf(range.startMonth) + 1,
      startYear: parseInt(range.startYear, 10),
      endMonth: monthNames.indexOf(range.endMonth) + 1,
      endYear: parseInt(range.endYear, 10),
    };
  };

  getUsageAnalyticsData = async (data) => {
    const config = {
      url: URLS.getUsageAnalytics,
      method: 'POST',
      data: this.getParsedMonthRange(data),
    };
    const response = await makeApi(config);
    this.usageAnalyticsData = response.data || { data: [] };
  };

  getLineChartData = (data) => {
    const { chartData, rangeData, rangeLength, defaultValue } = data;
    const range = rangeData.map((value) => {
      const [monthString, yearString] = value.split(' ');
      return {
        month: monthNames.indexOf(monthString) + 1,
        year: parseInt(yearString, 10),
      };
    });

    return Array.from({ length: rangeLength }).map((item, index) => {
      return (
        chartData.find((monthData) => {
          return (
            monthData.month === range[index]?.month &&
            monthData.year === range[index]?.year
          );
        })?.credits || defaultValue
      );
    });
  };

  getTeamMemberAnalyticsData = (rangeData, rangeLength) => {
    return this.getLineChartData({
      rangeData,
      rangeLength,
      chartData: this.usageAnalyticsData.data?.filter((data) => {
        return data?.memberId === userDetail.userInfo?.memberId;
      }),
      defaultValue: 0,
    });
  };

  getAdminOverallAnalyticsData = (rangeData, rangeLength) => {
    return this.getLineChartData({
      rangeData,
      rangeLength,
      chartData: this.usageAnalyticsData.data.filter((data) => {
        return data?.memberId === null;
      }),
      defaultValue: 0,
    });
  };

  getTeamBarChartData = (data) => {
    const { rangeData, rangeLength, barChartColors, memberData } = data;
    return memberData.map((member, index) => {
      return {
        label:
          !member.firstName && !member.lastName
            ? member.emailId
            : `${member.firstName || ''} ${member.lastName || ''}`,
        data: this.getLineChartData({
          rangeData,
          rangeLength,
          chartData: this.usageAnalyticsData.data.filter((monthData) => {
            return monthData?.memberId === member.memberId;
          }),
          defaultValue: null,
        }),
        backgroundColor: barChartColors[index],
      };
    });
  };

  getAPIUsageAnalytics = async (data, key) => {
    const { startDate, endDate, frequency } = data;
    const endDateForCondition = new Date(endDate);
    const adjustedEndDate = new Date(
      endDateForCondition.getFullYear(),
      endDateForCondition.getMonth(),
      endDateForCondition.getDate(),
      23,
      59,
      59,
    );

    const startDateForCondition = new Date(startDate);
    let adjustedStartDate = new Date(
      startDateForCondition.getFullYear(),
      startDateForCondition.getMonth(),
      startDateForCondition.getDate(),
      0,
      0,
      0,
    );

    adjustedStartDate =
      Utils.getStartDateComparedToSubscriptionCreatedDate(adjustedStartDate);

    this.apiUsageLoading = true;
    const config = {
      url: Utils.generateUrl(URLS.apiUsageAnalytics, {
        frequency,
        endDate: Number(adjustedEndDate),
        startDate: Number(adjustedStartDate),
      }),
      method: 'GET',
    };
    const response = await makeApi(config);

    const { data: apiData = [], ...remainingProps } = response?.data || {};

    if (key) {
      this.APIUsageAnalyticsData = {
        ...this.APIUsageAnalyticsData,
        [key]: apiData,
        ...remainingProps,
      };
    } else {
      this.APIUsageAnalyticsData = {
        search: apiData,
        purchase: apiData,
        enrich: apiData,
        ...remainingProps,
      };
    }

    this.apiUsageLoading = false;
  };
}

const profileState = new Profile();

export default profileState;
