/* eslint-disable quotes */
/* eslint-disable no-param-reassign */
import { observable, action, computed } from 'mobx';
import PhoneErrorIcon from 'Assets/svg/new_icons/phone-error.svg';
import { makeApi, URLS } from 'Utils/apiUtil';
import { loginValidate } from 'Utils/commonApi';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import { toasterState } from 'Components/common/base/Toaster';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import userDetail from 'Stores/userDetail';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import suppressionListState from 'Stores/suppressionList';
import { showFairUsageLimitPopup } from 'Pages/prospectSearch/contactSearch/modals';
import featureFlagsAndPreferencesState from './featureFlagsAndPreferences';
import advSearchFiltersState from './advSearchFilters';

const {
  CONTACT_PURCHASE_TYPES,
  CONTACT_EXPORT_TYPES,
  ASYNC_EXPORT_MIN_COUNT,
  ASYNC_TRANSACTION_MIN_COUNT,
  MX_AS_EVENTS,
  MX_PROFILE_PROPERTIES,
  SEOCookieQueryMapping,
} = CONSTANTS;

const {
  DIRECT_PURCHASE,
  ASYNC_PURCHASE_CRITERIA,
  LIMIT_CONTACT_PER_COMPANY,
  ASYNC_PURCHASE_IDS,
} = CONTACT_PURCHASE_TYPES;

const { DIRECT_EXPORT, ASYNC_EXPORT, ASYNC_EXPORT_CRITERIA } =
  CONTACT_EXPORT_TYPES;

let cancelAdvCall = null;

let cancelAdvCompanyCountCall = null;

class AdvanceSearchState {
  @observable accessor appliedFilters = [];

  @observable accessor resultStatusMsg = 'empty';

  @observable accessor appliedFilterPostData = {};

  @observable accessor bulkRangeCount = 0;

  @observable accessor appliedFilterSectionData = {};

  @observable accessor appliedFilterData = {};

  @observable accessor searchResults = [];

  @observable accessor searchResultCount = 0;

  @observable accessor searchCursorMark = [];

  @observable accessor pageLimit = 50;

  @observable accessor currentPage = 0;

  @observable accessor bulkPurchaseIds = [];

  @observable accessor selectedContactForDownload = {};

  @observable accessor hugeSelectionBulkIds = [];

  @observable accessor curListIds = [];

  @observable accessor resultLoading = false;

  @observable accessor allContactSelected = false;

  @observable accessor selectedAllContactFromPage = false;

  @observable accessor selectedContactFromRange = false;

  @observable accessor selectedAllContactAcrossPage = false;

  @observable accessor companyCountLoading = false;

  @observable accessor checkBoxFilters = {
    exactTitleMatch: false,
    dontDisplayOwnedContacts: true,
    dontDisplayTeamContacts: true,
    dontDisplayDeadContacts: true,
    displayOnlyDirectDialContacts: false,
    displayOnlyMobileNumberContacts: false,
    displayOnlyContactsWithAnyPhoneNumbers: false,
    showContactsWithoutEmail: false,
    showContactsWithEmail: true,
  };

  @observable accessor uploadCsv = {
    includedDomains: false,
    excludedDomains: false,
  };

  @observable accessor locationPreferences = '*';

  @observable accessor actionLoader = false;

  @observable accessor singlePurchaseLoaderIds = [];

  singlePurchaseType = '';

  @observable accessor contactLimitsPerCompany = 0;

  @observable accessor isExcludeIndustryFilter = false;

  @observable accessor isExcludeTitleFilter = false;

  @observable accessor uniqueCompanyCount = 0;

  @observable accessor companyCardData = {};

  @observable accessor startGroup = 0;

  @observable accessor asyncPurchaseOrExportMsg = '';

  @observable accessor showSalesforceAuthError = false;

  @computed
  get modifiedSearchResults() {
    return this.searchResults.map((result) => {
      const { paKeywords, lastFundingType } = result;
      return {
        ...result,
        paKeywords: advSearchFiltersState.getTextForValues(
          advSearchFiltersState.filterListValues.paKeywords,
          paKeywords,
        ),
        lastFundingType: advSearchFiltersState.getTextForValues(
          advSearchFiltersState.filterListValues.funding,
          lastFundingType?.length > 0
            ? [lastFundingType[lastFundingType.length - 1]]
            : [],
        )?.[0],
      };
    });
  }

  @action
  setBulkPurchaseIds(value) {
    this.bulkPurchaseIds = value;
  }

  @action
  setAsyncPurchaseOrExportMsg(value) {
    this.asyncPurchaseOrExportMsg = value;
  }

  @action
  setAllContactsInPageSelected(value) {
    this.selectedAllContactFromPage = value;
  }

  @action
  setIsExcludeIndustryFilter(value = false) {
    this.isExcludeIndustryFilter = value;
  }

  @action
  setIsExcludeTitleFilter(value = false) {
    this.isExcludeTitleFilter = value;
  }

  @action
  setCompanyCardData(companyId = null, data = null) {
    if (companyId !== null && data !== null) {
      const tempData = Utils.copyObjectWithoutReference(this.companyCardData);
      tempData[companyId] = data;
      this.companyCardData = tempData;
    }
  }

  getCompanyCardData(companyId = null) {
    if (companyId !== null && this.companyCardData[companyId]) {
      return this.companyCardData[companyId];
    }

    return null;
  }

  @action
  setActionLoader(value) {
    this.actionLoader = value;
  }

  @action
  updateSelectedBulkContactIds(index) {
    const obj = this.searchResults;
    if (index !== undefined) {
      const curObj = obj[index];
      const { id } = curObj;
      if (!this.bulkPurchaseIds.includes(id)) {
        this.bulkPurchaseIds.push(id);
        this.selectedContactForDownload[id] = curObj.downloaded;
      } else {
        const ind = this.bulkPurchaseIds.indexOf(id);
        this.bulkPurchaseIds.splice(ind, 1);
        delete this.selectedContactForDownload[id];
      }
    }

    this.checkIsAllContactSelected();
  }

  @action
  checkIsAllContactSelected() {
    const results = Utils.copyObjectWithoutReference(this.searchResults);
    let i = 0;
    results.map((item) => {
      if (this.bulkPurchaseIds.includes(item.id)) {
        i += 1;
      }
      return true;
    });
    if (i === results.length) {
      this.allContactSelected = true;
    } else {
      this.allContactSelected = false;
    }

    console.log('bulkPurchaseIds ', JSON.stringify(this.bulkPurchaseIds));
    console.log(
      'selectedContactForDownload ',
      JSON.stringify(this.selectedContactForDownload),
    );
  }

  @action
  updateSelectedBulkContactRange(selectionValue) {
    this.allContactSelected = false;
    this.bulkPurchaseIds = [];
    this.selectedContactForDownload = {};

    const results = JSON.parse(JSON.stringify(this.searchResults));

    const bulkIds = [];

    this.selectedContactFromRange = false;
    this.setAllContactsInPageSelected(false);
    this.selectedAllContactAcrossPage = false;

    const thisObj = this;
    if (this.pageLimit > selectionValue) {
      results.forEach((item, index) => {
        if (selectionValue > index) {
          if (!bulkIds.includes(item.id)) {
            thisObj.selectedContactForDownload[item.id] = item.downloaded;
            bulkIds.push(item.id);
          }
        }
      });
      this.allContactSelected = false;
    } else {
      results.forEach((item) => {
        if (!bulkIds.includes(item.id)) {
          thisObj.selectedContactForDownload[item.id] = item.downloaded;
          bulkIds.push(item.id);
        }
      });
      this.allContactSelected = true;
    }

    // this.searchResults = newResults;
    this.bulkPurchaseIds = bulkIds;

    if (bulkIds.length > 0) {
      const newDownObj = {};
      // eslint-disable-next-line array-callback-return
      bulkIds.map((item) => {
        if (this.selectedContactForDownload[item] !== undefined) {
          newDownObj[item] = this.selectedContactForDownload[item];
        }
      });
      this.selectedContactForDownload = newDownObj;
    } else {
      this.selectedContactForDownload = {};
    }
  }

  @action
  updateSelectedBulkContactAll(clearSelection) {
    if (clearSelection) {
      this.bulkRangeCount = 0;
      this.allContactSelected = true;
    }
    this.hugeSelectionBulkIds = [];

    const results = JSON.parse(JSON.stringify(this.searchResults));
    const bulkIds = JSON.parse(JSON.stringify(this.bulkPurchaseIds));
    const bulkDownloadObj = JSON.parse(
      JSON.stringify(this.selectedContactForDownload),
    );

    this.selectedContactFromRange = false;
    this.setAllContactsInPageSelected(false);
    this.selectedAllContactAcrossPage = false;
    if (this.allContactSelected) {
      results.forEach((item) => {
        if (bulkIds.includes(item.id)) {
          const ind = bulkIds.indexOf(item.id);
          bulkIds.splice(ind, 1);
        }
        if (bulkDownloadObj[item.id] !== undefined) {
          delete bulkDownloadObj[item.id];
        }
      });
      this.allContactSelected = false;
    } else {
      results.forEach((item) => {
        if (!bulkIds.includes(item.id)) {
          bulkIds.push(item.id);
          bulkDownloadObj[item.id] = item.downloaded;
        }
      });
      this.allContactSelected = true;
    }

    this.bulkPurchaseIds = bulkIds;
    this.selectedContactForDownload = bulkDownloadObj;
  }

  @action
  setPageLimit(limit) {
    if (limit > 0) {
      this.pageLimit = limit;
      if (this.searchCursorMark.length) {
        this.searchCursorMark.pop();
      }
      this.setAppliedFilters();
    }
  }

  @action
  setCurrentPage(pageNo, opr, changePagenoOnly = false) {
    if (!this.resultLoading) {
      if (opr === '-') {
        if (this.searchCursorMark.length) {
          this.searchCursorMark.pop();
          this.searchCursorMark.pop();
        }
      }

      this.bulkPurchaseIds = [];
      this.selectedContactForDownload = {};

      this.currentPage = pageNo;
      if (!changePagenoOnly) {
        this.setAppliedFilters(undefined, undefined, true);
      }
    }
  }

  setAllCheckboxFilters(newVal) {
    this.checkBoxFilters = newVal;
  }

  setAllDomainFilter(newVal) {
    this.uploadCsv = newVal;
  }

  @action
  setCheckBoxFilters(name) {
    if (name !== undefined && name !== '' && name !== null) {
      this.checkBoxFilters[name] = !this.checkBoxFilters[name];
      if (
        name === 'exactTitleMatch' &&
        this.appliedFilterPostData.title === undefined
      ) {
        this.setAppliedFilterPostData(this.appliedFilterData, false, false);
      } else {
        this.setAppliedFilterPostData(this.appliedFilterData);
      }
    }
  }

  @action
  setUploadCsv(key, value) {
    if (
      key !== undefined &&
      key !== '' &&
      key !== null &&
      value !== undefined
    ) {
      this.uploadCsv[key] = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
      this.appliedFilterSectionData[key] = [value.fname];
    }
  }

  @action
  setLocationPreferences(value) {
    if (value !== undefined && value !== '' && value !== null) {
      if (value === 'contact') {
        this.appliedFilterData.zip = [];
      }
      this.locationPreferences = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
    }
  }

  @action
  setContactLimitsPerCompany(value) {
    if (value !== undefined && value !== '' && value !== null) {
      this.contactLimitsPerCompany = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
    }
  }

  @action
  checkAndSetStartGroup() {
    const { appliedFilterPostData, currentPage } = this;
    const { contactLimitsPerCompany, limit } = appliedFilterPostData || {};
    if (
      contactLimitsPerCompany !== undefined &&
      contactLimitsPerCompany > 0 &&
      currentPage !== undefined
    ) {
      this.startGroup = Math.floor(
        (limit / contactLimitsPerCompany) * currentPage,
      );
    }
  }

  @action
  clearAllFilters() {
    if (window.location.hash !== '#search') {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/contact#search', { replace: true });
    }

    this.uploadCsv = {
      includedDomains: false,
      excludedDomains: false,
    };
    this.checkBoxFilters = {
      exactTitleMatch: false,
      dontDisplayOwnedContacts: true,
      dontDisplayTeamContacts: true,
      dontDisplayDeadContacts: true,
      displayOnlyDirectDialContacts: false,
      displayOnlyMobileNumberContacts: false,
      displayOnlyContactsWithAnyPhoneNumbers: false,
      showContactsWithoutEmail:
        userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail,
      showContactsWithEmail:
        userDetail.userInfo.searchFilterFlags.showContactsWithEmail,
    };
    this.resultStatusMsg = '';
    this.locationPreferences = '*';
    this.contactLimitsPerCompany = 0;
    this.startGroup = 0;

    Utils.setLocalStorage('searchJson', {});
    this.retainSearch();
  }

  @action
  domainSearchFilter(domainName) {
    this.appliedFilterData = {};
    const obj = {
      name: domainName,
      pName: domainName,
    };
    this.setAppliedFilters('companyName', [obj], null, true);
  }

  @action
  resetPageNumberToDefaultValue() {
    this.bulkPurchaseIds = [];
    this.selectedContactForDownload = {};
    this.searchCursorMark = [];
    this.currentPage = 0;
    this.startGroup = 0;
  }

  @action
  setAppliedFilters(key, value, isPagination, hashParam, performSearch = true) {
    if (key === 'industrySectors') {
      if (value.isAllIndustry === true) {
        value = '';
      }
    }

    const appliedFiltersTemp = this.appliedFilterData;

    // key null or undefined corresponds to pagination/limit change
    if (
      this.appliedFilterData.createdInLastNoOfDays &&
      key !== undefined &&
      key !== null
    ) {
      delete appliedFiltersTemp.createdInLastNoOfDays;
    }

    if (appliedFiltersTemp[key] === undefined && key !== undefined) {
      appliedFiltersTemp[key] = [];
    }

    if (key !== undefined && value !== undefined) {
      appliedFiltersTemp[key] = value;
      if (key === 'keyword') {
        appliedFiltersTemp.industryKeywordsJson = value;
      }
      if (performSearch) {
        this.searchCursorMark.pop();
      }
    }
    this.appliedFilterData = appliedFiltersTemp;
    if (isPagination === undefined || isPagination === null) {
      this.resetPageNumberToDefaultValue();
    }

    const seoSearch = Utils.getCookie('seo_search');
    if (seoSearch !== undefined && seoSearch !== null) {
      Utils.deleteCookie('seo_search');
    }

    this.setAppliedFilterPostData(
      appliedFiltersTemp,
      hashParam && key !== 'createdInLastNoOfDays',
      performSearch,
    );
  }

  @action
  setAppliedFilterPostData(data, hashParam, makeSearchCall = true) {
    let performSearch = makeSearchCall;
    this.clearAllSelection();
    this.checkAndSetStartGroup();
    if (hashParam !== undefined && hashParam === true) {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/contact#search', { replace: true });
    }

    const appliedKeys = Object.keys(data);
    const postData = {};
    postData.industryKeywords = [];
    postData.sectorKeywords = [];
    // eslint-disable-next-line array-callback-return
    appliedKeys.map((item) => {
      const keyItem = this.appliedFilterData[item];
      let keyValue = [];

      if (item === 'fundingData' || item === 'recentJobChanges') {
        postData[item] = this.appliedFilterData[item] || '';
      } else if (item === 'headcountGrowth') {
        const isMonthsAvailable =
          this.appliedFilterData[item].months !== undefined &&
          this.appliedFilterData[item].months !== '';
        postData[item] = this.appliedFilterData[item];
        if (!isMonthsAvailable || !keyItem?.percentage) {
          performSearch = false;
        }
      } else if (item === 'leadershipHire') {
        const isDaysValueAvailable =
          this.appliedFilterData[item].months !== undefined &&
          this.appliedFilterData[item].months !== '';

        postData[item] = this.appliedFilterData[item];
        if (!isDaysValueAvailable) {
          performSearch = false;
        }
      } else if (item === 'industryKeywords' || item === 'sectorKeywords') {
        console.log('dont do anything');
      } else if (item === 'industryKeywordsJson') {
        postData[item] = this.appliedFilterData[item];
        postData.industryKeywords = Utils.getIndustryKeywordArray(
          this.appliedFilterData[item],
        );
        postData.sectorKeywords = Utils.getSectorKeywordArray(
          this.appliedFilterData[item],
        );
      } else if (item === 'industrySectors') {
        if (this.appliedFilterData[item].industries !== undefined) {
          postData[item] = this.appliedFilterData[item];
        }
      } else if (keyItem.length > 0 && typeof keyItem === 'object') {
        if (
          [
            'selectedCitiesContact',
            'selectedStatesContact',
            'selectedCountriesContact',
            'selectedRegionsContact',
            'selectedCitiesCompany',
            'selectedStatesCompany',
            'selectedCountriesCompany',
            'selectedRegionsCompany',
          ].includes(item) ||
          item === 'selectedCities' ||
          item === 'selectedStates' ||
          item === 'selectedCountries' ||
          item === 'selectedRegions' ||
          item === 'selectedExcldedCountriesWholeData' ||
          item === 'industrySectorsV2' ||
          item === 'excludeIndustrySectorsV2'
        ) {
          console.log('selected cities');
          keyValue = keyItem.map((i) => {
            return i;
          });
        } else {
          // console.log('pre keyItem ', keyItem);
          keyValue = keyItem.map((i, eIndex) => {
            if (i !== undefined && i !== null && i.pName !== undefined) {
              return i.pName;
            }
            if (typeof i === 'string') {
              return i;
            }
            keyItem.splice(eIndex, 1);
            return '';
          });
        }
        if (keyValue.length > 0) {
          keyValue = keyValue.filter((el) => {
            return el !== null && el !== '';
          });
        }

        postData[item] = keyValue;
      } else if (item === 'createdInLastNoOfDays') {
        postData[item] = keyItem;
      }
    });

    if (this.uploadCsv.includedDomains) {
      postData.includedDomains = this.uploadCsv.includedDomains;
    }
    if (this.uploadCsv.excludedDomains) {
      postData.excludedDomains = this.uploadCsv.excludedDomains;
    }

    const postDataKeys = Object.keys(postData);
    postDataKeys.map((item) => {
      if (postData[item].length === 0 || postData[item] === '') {
        delete postData[item];
      }

      if (item === 'exclusionListIds') {
        delete postData[item];
      }
      // migrated to v2 obj
      if (item === 'companyKeyword') {
        delete postData[item];
      }
      if (item === 'industrySectors') {
        delete postData[item];
      }
      return true;
    });
    if (postData.industryName !== undefined) {
      delete postData.industryName;
    }

    if (
      Object.keys(postData).length > 0 &&
      featureFlagsAndPreferencesState.isSuppressionListEnabled()
    ) {
      // Add Suppression list
      const { suppressionListEntries = [] } = suppressionListState;

      const exclusionListIds = [];
      // eslint-disable-next-line array-callback-return
      suppressionListEntries.map((item) => {
        const { listId, listName, status, enabled } = item;
        if (status === 'COMPLETE' && enabled && listId !== null) {
          exclusionListIds.push({ listId, listName });
        }
      });
      if (exclusionListIds.length) {
        postData.exclusionListIds = exclusionListIds;
      }
    }

    this.appliedFilterSectionData = postData;
    advSearchFilterPopupState.appliedFilterSectionData = postData;
    advSearchFilterPopupState.appliedFilterData = JSON.parse(
      JSON.stringify(this.appliedFilterData),
    );
    advSearchFilterPopupState.locationPreferences = JSON.parse(
      JSON.stringify(this.locationPreferences),
    );
    advSearchFilterPopupState.contactLimitsPerCompany = JSON.parse(
      JSON.stringify(this.contactLimitsPerCompany),
    );
    advSearchFilterPopupState.checkBoxFilters = JSON.parse(
      JSON.stringify(this.checkBoxFilters),
    );
    advSearchFilterPopupState.uploadCsv = JSON.parse(
      JSON.stringify(this.uploadCsv),
    );
    if (this.uploadCsv.includedDomains) {
      this.appliedFilterSectionData.includedDomains = [
        this.uploadCsv.includedDomains.fname,
      ];
      advSearchFilterPopupState.appliedFilterSectionData.includedDomains = [
        this.uploadCsv.includedDomains.fname,
      ];
    }
    if (this.uploadCsv.excludedDomains) {
      this.appliedFilterSectionData.excludedDomains = [
        this.uploadCsv.excludedDomains.fname,
      ];
      advSearchFilterPopupState.appliedFilterSectionData.excludedDomains = [
        this.uploadCsv.excludedDomains.fname,
      ];
    }

    if (Object.keys(postData).length > 0) {
      const postDataCheckObj = Utils.copyObjectWithoutReference(postData);
      if (postDataCheckObj.exclusionListIds !== undefined) {
        delete postDataCheckObj.exclusionListIds;
      }

      if (
        Object.keys(postDataCheckObj).length === 1 &&
        (postData.selectedCountries !== undefined ||
          (postData.industrySectors !== undefined &&
            postData.industrySectors.industries === undefined))
      ) {
        this.resultStatusMsg = 'one_more';
        this.searchResults = [];
        this.searchResultCount = 0;
        this.uniqueCompanyCount = 0;
      } else {
        this.resultStatusMsg = '';
      }
      advSearchFilterPopupState.resultStatusMsg = this.resultStatusMsg;
      postData.dontDisplayDeadContacts =
        this.checkBoxFilters.dontDisplayDeadContacts;
      postData.dontDisplayOwnedContacts =
        this.checkBoxFilters.dontDisplayOwnedContacts;
      postData.dontDisplayTeamContacts =
        this.checkBoxFilters.dontDisplayTeamContacts;
      postData.displayOnlyDirectDialContacts =
        this.checkBoxFilters.displayOnlyDirectDialContacts;
      postData.showContactsWithoutEmail =
        this.checkBoxFilters.showContactsWithoutEmail;
      postData.showContactsWithEmail =
        this.checkBoxFilters.showContactsWithEmail;
      postData.displayOnlyMobileNumberContacts =
        this.checkBoxFilters.displayOnlyMobileNumberContacts;
      postData.displayOnlyContactsWithAnyPhoneNumbers =
        this.checkBoxFilters.displayOnlyContactsWithAnyPhoneNumbers;
      postData.locationPreferences = this.locationPreferences;
      postData.contactLimitsPerCompany = this.contactLimitsPerCompany;
      postData.startGroup = this.startGroup;

      if (this.checkBoxFilters.exactTitleMatch) {
        postData.exactTitleMatch = this.checkBoxFilters.exactTitleMatch;
      } else {
        postData.exactTitleMatch = false;
      }
      postData.limit = this.pageLimit;

      postData.offset = this.pageLimit * this.currentPage;

      if (userDetail?.userInfo?.esSearchEnabled) {
        if (this.searchCursorMark.length && this.currentPage > 0) {
          postData.cursorMark =
            this.searchCursorMark[this.searchCursorMark.length - 1];
        } else {
          delete postData.cursorMark;
        }
      }

      if (this.currentPage > 0) {
        postData.currentPage = this.currentPage;
      } else {
        delete postData.currentPage;
      }

      advSearchFilterPopupState.appliedFilterPostData = JSON.parse(
        JSON.stringify(postData),
      );

      Utils.setLocalStorage('searchJson', postData);
      const contactSeoUrl = Utils.getCookie(
        SEOCookieQueryMapping.seoContactURL,
      );

      if (contactSeoUrl) {
        postData.seo_path = contactSeoUrl;
      }

      this.appliedFilterPostData = postData;

      if (this.resultStatusMsg !== 'one_more' && performSearch) {
        if (
          Object.keys(postData).some((key) => {
            return [
              'selectedCitiesContact',
              'selectedStatesContact',
              'selectedCountriesContact',
              'selectedRegionsContact',
              'selectedCitiesCompany',
              'selectedStatesCompany',
              'selectedCountriesCompany',
              'selectedRegionsCompany',
            ].includes(key);
          }) &&
          !featureFlagsAndPreferencesState.isEUSearchEnabled()
        ) {
          this.checkEuCountrySearch();
        } else {
          this.getSearchResults();
        }
      }
    } else {
      if (cancelAdvCall !== null) {
        cancelAdvCall('cancel');
        cancelAdvCall = null;
        this.searchLoading = false;
        this.resultLoading = false;
      }
      this.resultStatusMsg = 'empty';
      advSearchFilterPopupState.resultStatusMsg = this.resultStatusMsg;
      postData.dontDisplayDeadContacts =
        this.checkBoxFilters.dontDisplayDeadContacts;
      postData.dontDisplayOwnedContacts =
        this.checkBoxFilters.dontDisplayOwnedContacts;
      postData.dontDisplayTeamContacts =
        this.checkBoxFilters.dontDisplayTeamContacts;
      postData.displayOnlyDirectDialContacts =
        this.checkBoxFilters.displayOnlyDirectDialContacts;
      postData.showContactsWithoutEmail =
        userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail;
      postData.showContactsWithEmail =
        userDetail.userInfo.searchFilterFlags.showContactsWithEmail;
      postData.displayOnlyMobileNumberContacts =
        this.checkBoxFilters.displayOnlyMobileNumberContacts;
      postData.displayOnlyContactsWithAnyPhoneNumbers =
        this.checkBoxFilters.displayOnlyContactsWithAnyPhoneNumbers;
      postData.locationPreferences = this.locationPreferences;
      postData.contactLimitsPerCompany = this.contactLimitsPerCompany;
      postData.startGroup = this.startGroup;

      if (this.checkBoxFilters.exactTitleMatch) {
        postData.exactTitleMatch = this.checkBoxFilters.exactTitleMatch;
      } else {
        postData.exactTitleMatch = false;
      }

      Utils.setLocalStorage('searchJson', postData);
      this.appliedFilterPostData = {};
      advSearchFilterPopupState.appliedFilterPostData = {};
      this.searchResultCount = 0;
      this.uniqueCompanyCount = 0;
      advSearchFilterPopupState.searchResultCount = 0;
      advSearchFilterPopupState.uniqueCompanyCount = 0;
      this.searchResults = [];
      advSearchFilterPopupState.searchResults = [];
      this.contactLimitsPerCompany = 0;
      advSearchFilterPopupState.contactLimitsPerCompany = 0;
    }
  }

  @action
  checkEuCountrySearch = () => {
    const config = {
      url: URLS.checkEuCountry,
      method: 'POST',
      data: this.appliedFilterPostData,
    };
    const thisObj = this;
    this.resultLoading = true;
    makeApi(config)
      .then((response) => {
        thisObj.resultLoading = false;
        if (
          response &&
          response.data &&
          response.data.showEUMessage !== undefined &&
          response.data.showEUMessage === true
        ) {
          if (featureFlagsAndPreferencesState.isEUSearchEnabled()) {
            thisObj.getSearchResults();
          } else {
            Utils.showEURestrictionPopup();
          }
        } else {
          this.getSearchResults();
        }
      })
      .catch((error) => {
        this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  };

  @action
  updateSearchResult = (newList = []) => {
    if (newList !== null && newList.length > 0) {
      const updatedList = Utils.copyObjectWithoutReference(this.searchResults);

      newList.forEach((item) => {
        const newObj = item ?? {};

        if (this.curListIds.includes(newObj.id)) {
          const ind = this.curListIds.indexOf(newObj.id);
          const searchObjData = updatedList[ind];
          searchObjData.email = newObj.email || null;
          searchObjData.phoneNumberFromVendor =
            newObj.phoneNumberFromVendor ||
            searchObjData.phoneNumberFromVendor ||
            null;
          searchObjData.owned = newObj.owned || false;
          searchObjData.emailValid = newObj.emailValid;
          searchObjData.memberContactId = newObj.memberContactId;
          searchObjData.emailValidationStatus = newObj.emailValidationStatus;
          updatedList[ind] = searchObjData;
        }
      });

      this.searchResults = updatedList;

      this.allContactSelected = true;
      this.updateSelectedBulkContactAll();
    }
  };

  clearAdditionalCookieDataAfterResult() {
    Utils.deleteCookie(SEOCookieQueryMapping.seoContactURL);
  }

  @action
  getCompanyCount() {
    const userType = Utils.getUserType();
    const pageType = 'LB';

    const postData = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );
    postData.companyCount = true;
    postData.limit = 0;
    const config = {
      url: `${URLS.searchResults}?userType=${userType}&pageType=${pageType}`,
      method: 'POST',
      data: postData,
    };

    this.companyCountLoading = true;

    if (cancelAdvCompanyCountCall !== null) {
      cancelAdvCompanyCountCall('cancel');
      cancelAdvCompanyCountCall = null;
    }
    const advCancelCallback = (value) => {
      cancelAdvCompanyCountCall = value;
    };

    makeApi(config, false, advCancelCallback)
      .then((response) => {
        if (response && response.message === undefined) {
          this.companyCountLoading = false;

          if (response.data.companyCount !== null) {
            this.uniqueCompanyCount = response.data.companyCount;
            advSearchFilterPopupState.uniqueCompanyCount =
              response.data.companyCount;
          }
        }
      })
      .catch(() => {
        this.companyCountLoading = false;
      });
  }

  @action
  getSearchResults() {
    const thisObj = this;

    const userType = Utils.getUserType();
    const pageType = 'LB';

    const postData = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );

    const config = {
      url: `${URLS.searchResults}?userType=${userType}&pageType=${pageType}`,
      method: 'POST',
      data: postData,
    };

    if (cancelAdvCall !== null) {
      cancelAdvCall('cancel');
      cancelAdvCall = null;
    }
    const advCancelCallback = (value) => {
      cancelAdvCall = value;
    };

    this.resultLoading = true;
    this.searchLoading = true;

    makeApi(config, false, advCancelCallback)
      .then((response) => {
        if (response && response.message === undefined) {
          if (this.currentPage <= 0) {
            this.getCompanyCount();

            Utils.totangoTrack(
              CONSTANTS.TOTANGO.EVENTS.SEARCH,
              CONSTANTS.TOTANGO.MODULES.LB,
            );
          }
          thisObj.searchLoading = false;

          this.searchResults = [];
          this.searchResultCount = 0;
          if (
            response &&
            response.data &&
            response.data.contacts &&
            response.data.contacts.length > 0
          ) {
            const results = response.data.contacts;
            this.searchResults = results;
            advSearchFilterPopupState.searchResults = results;
            const ids = response.data.contacts.map((item) => {
              return item && item.id ? item.id : '';
            });
            this.curListIds = ids;
            this.checkIsAllContactSelected();
          } else {
            this.searchResults = [];
          }
          if (response && response.data && response.data.hits > 0) {
            this.searchResultCount = response.data.hits;
            advSearchFilterPopupState.searchResultCount = response.data.hits;
          }

          thisObj.trackSearchEvent();
          thisObj.clearAdditionalCookieDataAfterResult();

          if (
            response &&
            response.data &&
            response.data.cursorMark !== undefined
          ) {
            this.searchCursorMark.push(response.data.cursorMark);
          }

          thisObj.resultLoading = false;
        }
      })
      .catch((error) => {
        thisObj.resultLoading = false;
        thisObj.searchLoading = false;
        console.log('suggestionList error ', error);
      });
  }

  getSearchMxData() {
    const thisObj = this;
    const { appliedFilterPostData } = thisObj;
    const {
      dept = [],
      level = [],
      selectedStates = [],
      selectedCountries = [],
      selectedRegions = [],
      industrySectorsV2 = [],
      companyKeywordV2 = [],
      excludeIndustrySectorsV2 = [],
      excludeCompanyKeywordV2 = [],
    } = appliedFilterPostData;

    const objKeys = Object.keys(appliedFilterPostData);
    const mxData = {};

    objKeys.forEach((item) => {
      if (item === 'dept') {
        mxData.department = dept;
      } else if (item === 'level') {
        mxData.level = level;
      } else if (
        item === 'selectedStates' ||
        item === 'selectedCountries' ||
        item === 'selectedRegions'
      ) {
        const location = [
          ...selectedStates,
          ...selectedCountries,
          ...selectedRegions,
        ];
        mxData.location = location.map((item1) => {
          return item1.name;
        });
      } else if (item === 'industrySectorsV2') {
        mxData.industry = industrySectorsV2.map((item1) => {
          return item1.name;
        });
      } else if (item === 'companyKeywordV2') {
        mxData.companyKeyword = companyKeywordV2;
      } else if (item === 'excludeIndustrySectorsV2') {
        mxData.excludeIndustry = excludeIndustrySectorsV2.map((item1) => {
          return item1.name;
        });
      } else if (item === 'excludeCompanyKeywordV2') {
        mxData.excludeCompanyKeyword = excludeCompanyKeywordV2;
      }
    });

    return mxData;
  }

  @action
  trackSearchEvent() {
    const thisObj = this;

    const {
      TOTAL_SEARCHES_PERFORMED,
      LAST_SEARCH_PERFORMED,
      TOTAL_SEARCH_RESULT_COUNT,
      TOTAL_SEARCH_COMPANY_RESULT_COUNT,
    } = MX_PROFILE_PROPERTIES;
    Utils.mixpanelIncrementProfileProperty(
      TOTAL_SEARCH_RESULT_COUNT,
      thisObj.searchResultCount,
    );
    Utils.mixpanelIncrementProfileProperty(
      TOTAL_SEARCH_COMPANY_RESULT_COUNT,
      thisObj.uniqueCompanyCount,
    );

    Utils.mixpanelIncrementProfileProperty(TOTAL_SEARCHES_PERFORMED);
    Utils.mixpanelUpdateProfileProperty({
      [LAST_SEARCH_PERFORMED]: new Date().toISOString(),
    });
    const mxData = thisObj.getSearchMxData();
    mxData.contactCount = thisObj.searchResultCount;
    mxData.companyCount = thisObj.uniqueCompanyCount;
    mxData.searchQuery = this.appliedFilterPostData;
    mxData.searchType = 'Contact';
    mxData.zeroBounce = true;
    mxData.filterPlace = 'page';
    Utils.mixpanelTrack(MX_AS_EVENTS.AS_LB_SEARCH_PERFORMED, mxData);
    if (thisObj.searchResultCount > 0) {
      Utils.trackRefinerSearchPerformedCount();

      Utils.trackRefinerContactOrCompanyFilterApplied(
        thisObj.appliedFilterPostData,
        thisObj.searchResultCount,
      );
    }
  }

  @action
  trackPurchaseEvent(newList, type = '', count, purchaseFlowType) {
    const thisObj = this;

    const mxData = thisObj.getSearchMxData();
    mxData['List Created'] = newList;
    mxData['Number of contacts purchased'] = count;
    mxData['Purchase Type'] =
      CONSTANTS.CRM_EXPORT_TYPE[type.toUpperCase()] || type;
    mxData.searchQuery = this.appliedFilterPostData;
    mxData.zeroBounce = true;
    mxData.searchType = 'Contact';
    mxData.currentTab = 'contact';
    mxData.purchaseFlowType = purchaseFlowType;
    Utils.mixpanelTrack(MX_AS_EVENTS.AS_LB_CONTACT_PURCHASED, mxData);
    Utils.trackRefinerRemainingCredits();
  }

  @action
  selectHugeContacts(limit, allFromSearch) {
    const thisObj = this;
    this.clearAllSelection();
    thisObj.bulkRangeCount = limit;

    if (allFromSearch) {
      this.bulkPurchaseIds = [];
      this.selectedContactForDownload = {};
      this.allContactSelected = false;
      this.updateSelectedBulkContactAll();

      this.selectedContactFromRange = false;
      this.setAllContactsInPageSelected(false);
      this.selectedAllContactAcrossPage = true;
    } else {
      this.updateSelectedBulkContactRange(limit);

      this.selectedAllContactAcrossPage = false;
      this.setAllContactsInPageSelected(false);
      this.selectedContactFromRange = true;
    }
    this.hugeSelectionBulkIds = this.bulkPurchaseIds;
  }

  @action
  clearHugePurchaseIds() {
    this.bulkRangeCount = 0;
    this.hugeSelectionBulkIds = [];
    this.selectedAllContactAcrossPage = false;
    this.selectedAllContactFromPage = false;
    this.selectedContactFromRange = false;
  }

  @action
  clearAllSelection(doClearCheckBox = false) {
    const thisObj = this;
    thisObj.clearHugePurchaseIds();
    thisObj.bulkPurchaseIds = [];
    thisObj.selectedContactForDownload = {};
    if (doClearCheckBox) {
      thisObj.updateSelectedBulkContactAll(true);
    }
  }

  @action
  getExportIdsPostData(contactIds = []) {
    const thisObj = this;
    const postData = {};

    const contactsToAdd =
      contactIds.length === 0 ? thisObj.bulkPurchaseIds : contactIds;

    const contactPurchaseCount = thisObj.bulkRangeCount
      ? thisObj.bulkRangeCount
      : contactsToAdd.length;

    if (
      (thisObj.selectedContactFromRange ||
        thisObj.selectedAllContactAcrossPage) &&
      thisObj.bulkRangeCount
    ) {
      if (thisObj.bulkRangeCount < ASYNC_TRANSACTION_MIN_COUNT) {
        const cIds = [];
        for (let i = 0; i < thisObj.bulkRangeCount; i += 1) {
          cIds.push(thisObj.searchResults[i].id);
        }

        postData.contactIds = JSON.stringify(cIds);

        const criteria = Utils.copyObjectWithoutReference(
          thisObj.appliedFilterPostData,
        );
        criteria.limit = cIds.length;
        postData.saveSearchCriteria = JSON.stringify(criteria);
      } else {
        const criteria = Utils.copyObjectWithoutReference(
          thisObj.appliedFilterPostData,
        );
        criteria.limit = thisObj.bulkRangeCount;
        criteria.startGroup = 0;
        postData.searchCriteria = JSON.stringify(criteria);
      }
    } else if (contactPurchaseCount > 0) {
      let contactIdArr = [];
      if (contactIds.length) {
        contactIdArr = contactIds;
      } else {
        contactIdArr = thisObj.bulkPurchaseIds;
      }
      postData.contactIds = JSON.stringify(contactIdArr);

      const criteria = Utils.copyObjectWithoutReference(
        thisObj.appliedFilterPostData,
      );
      criteria.limit = contactIdArr.length;
      postData.saveSearchCriteria = JSON.stringify(criteria);
    }
    postData.showContactsWithoutEmail =
      this.checkBoxFilters.showContactsWithoutEmail;
    postData.showContactsWithEmail = this.checkBoxFilters.showContactsWithEmail;

    return postData;
  }

  @action
  getSelectedContactsCount() {
    const thisObj = this;
    let contactPurchaseCount = thisObj.bulkPurchaseIds
      ? thisObj.bulkPurchaseIds.length
      : 1;

    if (thisObj.bulkRangeCount) {
      contactPurchaseCount = thisObj.bulkRangeCount;
    }

    return contactPurchaseCount;
  }

  getExportType() {
    const thisObj = this;
    const contactsToAdd = thisObj.bulkPurchaseIds;

    const contactPurchaseCount = thisObj.bulkRangeCount
      ? thisObj.bulkRangeCount
      : contactsToAdd.length;

    let purchaseType = '';

    if (
      (thisObj.selectedContactFromRange ||
        thisObj.selectedAllContactAcrossPage) &&
      thisObj.bulkRangeCount
    ) {
      if (thisObj.bulkRangeCount < ASYNC_EXPORT_MIN_COUNT) {
        purchaseType = DIRECT_EXPORT;
      } else {
        purchaseType = ASYNC_EXPORT_CRITERIA;
      }
    } else if (contactPurchaseCount > 0) {
      if (contactPurchaseCount < ASYNC_EXPORT_MIN_COUNT) {
        purchaseType = DIRECT_EXPORT;
      } else {
        purchaseType = ASYNC_EXPORT;
      }
    }

    return purchaseType;
  }

  getCompanyInfoById = (companyId, callback = () => {}) => {
    const config = {
      url: URLS.getCompanyInfoById,
      method: 'POST',
      data: {
        companyId,
      },
    };

    makeApi(config, false)
      .then((response) => {
        console.log('getCompanyInfoById response ', response);
        if (response && response.data) {
          callback(response.data);
        } else {
          callback(null);
        }
      })
      .catch((error) => {
        console.log('getCompanyInfoById error ', error);
      });
  };

  @action
  applySearch(localValue, resetPageNumber = false) {
    const thisObj = this;
    const localValKeys = Object.keys(localValue);

    thisObj.appliedFilterData = {};
    if (resetPageNumber) {
      thisObj.resetPageNumberToDefaultValue();
    }

    if (localValKeys.length) {
      localValKeys.map((item) => {
        if (item === 'keyword') {
          console.log('dont do anything');
        } else if (item === 'limit') {
          thisObj.pageLimit = localValue[item];
        } else if (item === 'locationPreferences') {
          thisObj.locationPreferences = localValue[item];
        } else if (item === 'includedDomains' || item === 'excludedDomains') {
          thisObj.uploadCsv[item] = localValue[item];
        } else if (
          item === 'exactTitleMatch' ||
          item === 'dontDisplayOwnedContacts' ||
          item === 'dontDisplayTeamContacts' ||
          // item === 'dontDisplayDeadContacts' ||
          item === 'displayOnlyDirectDialContacts' ||
          item === 'displayOnlyMobileNumberContacts' ||
          item === 'displayOnlyContactsWithAnyPhoneNumbers' ||
          item === 'showContactsWithoutEmail' ||
          item === 'showContactsWithEmail'
        ) {
          thisObj.checkBoxFilters[item] = localValue[item];
        } else if (
          [
            'selectedCitiesContact',
            'selectedStatesContact',
            'selectedCountriesContact',
            'selectedRegionsContact',
            'selectedCitiesCompany',
            'selectedStatesCompany',
            'selectedCountriesCompany',
            'selectedRegionsCompany',
          ].includes(item) ||
          item === 'selectedCities' ||
          item === 'selectedStates' ||
          item === 'selectedCountries' ||
          item === 'selectedRegions' ||
          item === 'selectedExcldedCountriesWholeData' ||
          item === 'industrySectorsV2' ||
          item === 'excludeIndustrySectorsV2' ||
          item === 'createdInLastNoOfDays' ||
          item === 'leadershipHire' ||
          item === 'headcountGrowth' ||
          item === 'fundingData' ||
          item === 'recentJobChanges'
        ) {
          thisObj.appliedFilterData[item] = localValue[item];
        } else if (item === 'industrySectors') {
          thisObj.appliedFilterData[item] = localValue[item];
        } else if (item === 'industryKeywordsJson') {
          thisObj.appliedFilterData[item] = localValue[item];
          thisObj.appliedFilterData.keyword = localValue[item];
        } else if (item === 'contactLimitsPerCompany') {
          thisObj.contactLimitsPerCompany = localValue[item];
        } else if (item === 'startGroup') {
          thisObj.startGroup = localValue[item];
        } else {
          let formattedValue = '';
          if (
            localValue[item] !== undefined &&
            localValue[item] !== null &&
            localValue[item].length &&
            typeof localValue[item] === 'object'
          ) {
            formattedValue = localValue[item];
            formattedValue = localValue[item].map((i) => {
              return {
                name: i,
                pName: i,
              };
            });
          }

          thisObj.appliedFilterData[item] = formattedValue;
        }

        return true;
      });
      this.setAppliedFilterPostData(this.appliedFilterData);
    } else {
      this.uploadCsv = {
        includedDomains: false,
        excludedDomains: false,
      };
      this.setAppliedFilterPostData({});
    }
  }

  @action
  retainSearch() {
    if (window.location.hash === '#search') {
      const seoSearchJson = Utils.getCookie('seo_searchJson');
      let localValue = null;
      if (
        seoSearchJson !== undefined &&
        seoSearchJson !== null &&
        seoSearchJson !== ''
      ) {
        localValue = JSON.parse(seoSearchJson);
        Utils.deleteCookie('seo_searchJson');
      } else {
        localValue = Utils.getLocalStorage('searchJson');
      }

      if (localValue !== undefined && localValue !== null) {
        const modifiedData = this.checkAndAddEmailStatus(localValue);
        this.applySearch(modifiedData);
      } else {
        this.searchResults = [];
        this.appliedFilterData = {};
        this.searchResultCount = 0;
        this.uniqueCompanyCount = 0;
        this.setAppliedFilterPostData({});
      }
    } else {
      localStorage.removeItem('searchJson');
      this.searchResults = [];
      this.appliedFilterData = {};
      this.searchResultCount = 0;
      const freshValue = {
        dontDisplayDeadContacts: true,
        dontDisplayOwnedContacts: true,
        dontDisplayTeamContacts: true,
        locationPreferences: '*',
        exactTitleMatch: false,
        displayOnlyMobileNumberContacts: false,
        displayOnlyDirectDialContacts: false,
        displayOnlyContactsWithAnyPhoneNumbers: false,
        showContactsWithoutEmail:
          userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail,
        showContactsWithEmail:
          userDetail.userInfo.searchFilterFlags.showContactsWithEmail,
      };
      // this.setAppliedFilterPostData(this.appliedFilterData);
      this.applySearch(freshValue);
    }
  }

  checkAndAddEmailStatus = (data, existingBehavior = false) => {
    const modifiedData = Utils.copyObjectWithoutReference(data);

    if (Object.keys(data).length > 0) {
      if (modifiedData.showContactsWithEmail === undefined) {
        modifiedData.showContactsWithEmail = existingBehavior
          ? true
          : userDetail.userInfo.searchFilterFlags.showContactsWithEmail;
      }
      if (modifiedData.showContactsWithoutEmail === undefined) {
        modifiedData.showContactsWithoutEmail = existingBehavior
          ? false
          : userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail;
      }

      return modifiedData;
    }
    return data;
  };

  saveEmailSearchPreferenceToAPI = (data) => {
    const config = {
      url: URLS.saveEmailSearchPref,
      method: 'POST',
      data,
    };

    userDetail.updateUserInfo({
      searchFilterFlags: data,
    });

    makeApi(config);
  };

  purchaseEmail = async (contactId) => {
    const response = await this.purchaseContact({
      contactId,
      required: 'EMAIL',
    });

    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.contactView?.email &&
      responseData?.contactView?.email?.indexOf('*') === -1
    ) {
      if (responseData?.creditReduced) {
        toasterState.setToastMsg(
          '1 email credit used',
          CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
        );
      } else {
        toasterState.setToastMsg(
          '1 email added to my lists, no credit reduced',
          CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
        );
      }
    } else if (
      response.data?.responseCode === 'ORC_FAIR_USAGE_REACH_DAILY_LIMIT_001'
    ) {
      showFairUsageLimitPopup();
    } else if (response.data?.existsInCrm) {
      toasterState.setToastMsg('This contact already exists in your CRM');
    } else if (response.data?.responseCode === 'ORC_SYNC_PURCHASE_005') {
      const mxData = {
        noContacts: 1,
        purchaseType: DIRECT_PURCHASE,
      };
      if (response.data?.responseMsg?.toLowerCase() === 'individual limit') {
        Utils.mixpanelTrack(MX_AS_EVENTS.AS_PAYWALL_PRICING_POPUP, mxData);
        Utils.showCreditExpiredPopup();
      } else {
        Utils.mixpanelTrack(MX_AS_EVENTS.AS_DOWNLOAD_EXPIRED, mxData);
        Utils.showUserPricingPopup();
      }
    } else if (response.data?.responseCode === 'ORC_SYNC_PURCHASE_007') {
      this.showSalesforceAuthError = true;
    } else if (
      response.status !== 200 ||
      response.data?.responseMsg ||
      (response.status === 200 && !response.data?.contactView)
    ) {
      toasterState.setToastMsg(
        response.data?.responseMsg || 'Sorry, we are unable to find email',
        CONSTANTS.TOAST_MSG_TYPES.FAILURE,
      );
    }
  };

  showPhoneCreditsExhaustedPopup = () => {
    commonModalState.setShowModal(true, {
      Icon: PhoneErrorIcon,
      title: "You've run out of phone credits",
      description: "Don't miss out on your next big deal!",
      primaryButtonText: 'Upgrade Now',
      buttonCallback: () => {
        Utils.upgradeNowClick('phone credit exhausted');
      },
    });
  };

  purchaseMobileNumber = async (contactId) => {
    Utils.mixpanelTrack(CONSTANTS.MOBILE_NUMBER_EVENTS.PURCHASE_MOBILE, {
      contactId,
    });

    const response = await this.purchaseContact({
      contactId,
      required: 'PHONE',
    });

    if (response.data?.contactView?.phoneNumberFromVendor === null) {
      if (response.data?.contactView) {
        this.updateSearchResult([
          {
            ...(response.data?.contactView || {}),
            phoneNumberFromVendor: [],
          },
        ]);
      }
    }

    if (response.data?.existsInCrm) {
      toasterState.setToastMsg('This contact already exists in your CRM');
    } else if (
      response.status === 200 &&
      response.data?.contactView?.phoneNumberFromVendor?.length > 0
    ) {
      toasterState.setToastMsg(
        '1 phone credit used',
        CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
      );
    } else if (response.data?.responseCode === 'ORC_SYNC_PURCHASE_005') {
      this.showPhoneCreditsExhaustedPopup();
      Utils.mixpanelTrack(
        CONSTANTS.MOBILE_NUMBER_EVENTS.PHONE_CREDITS_EXPIRED_POPUP_SHOWN,
      );
    } else if (
      response.status !== 200 ||
      response.data?.responseMsg ||
      response.data?.contactView?.phoneNumberFromVendor === null
    ) {
      toasterState.setToastMsg(
        response.data?.responseMsg ||
          'Sorry, we are unable to find mobile number',
        CONSTANTS.TOAST_MSG_TYPES.FAILURE,
      );
    }
  };

  @action
  notifyMobileNumberInterest = async () => {
    const config = {
      url: URLS.notifyMobileNumberInterest,
      method: 'GET',
    };

    this.setActionLoader(true);

    await makeApi(config);

    Utils.mixpanelTrack(CONSTANTS.MOBILE_NUMBER_EVENTS.SHOW_INTEREST);

    this.setActionLoader(false);

    // Utils.showFeedbackPopup(response.data, 'sales@adapt.io');
  };

  singlePurchaseLoaderAction = (required, contactId) => {
    if (this.actionLoader) {
      this.setActionLoader(false);
      this.singlePurchaseType = required;
      this.singlePurchaseLoaderIds.push(contactId);
    }
  };

  purchaseContact = async (data) => {
    this.setActionLoader(true);

    const { contactId, required } = data;
    const criteria = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );
    criteria.limit = 1;

    const postData = { contactIds: [contactId], required, criteria };

    const config = {
      url: URLS.purchase.sync,
      method: 'POST',
      data: postData,
    };

    const timeout = setTimeout(
      this.singlePurchaseLoaderAction.bind(null, required, contactId),
      10000,
    );

    const response = await makeApi(config);

    this.setActionLoader(false);
    this.singlePurchaseLoaderIds.pop(contactId);
    this.singlePurchaseType = '';
    clearTimeout(timeout);

    if (response.status === 200 && response.data?.contactView) {
      this.trackPurchaseEvent(false, 'View', 1, DIRECT_PURCHASE);
      if (response.data?.contactView) {
        this.updateSearchResult([response.data?.contactView]);
      }
      loginValidate(false);
    }

    return response;
  };

  // eslint-disable-next-line consistent-return
  getRequiredPropertyForPurchase = (purchaseFlags) => {
    if (purchaseFlags.email && purchaseFlags.phone) {
      return 'EMAIL_AND_PHONE';
    }
    if (purchaseFlags.email) {
      return 'EMAIL';
    }
    if (purchaseFlags.phone) {
      return 'PHONE';
    }
  };

  purchaseContactAsync = async (data, newList) => {
    const {
      listId,
      required,
      limitContactsPerCompany = false,
      limit,
      limitPerDomain,
      validationStatus,
    } = data;
    let purchaseType;
    const request = { required };
    const contactPurchaseCount = this.bulkRangeCount
      ? this.bulkRangeCount
      : this.bulkPurchaseIds.length;

    const criteria = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );

    if (limitContactsPerCompany) {
      purchaseType = LIMIT_CONTACT_PER_COMPANY;
      criteria.companyCount = this.uniqueCompanyCount;
      criteria.limit = limit || this.uniqueCompanyCount * limitPerDomain;
      criteria.limitPerDomain = limitPerDomain;
      criteria.enableDomainLimit = true;
    } else if (
      (this.selectedContactFromRange || this.selectedAllContactAcrossPage) &&
      this.bulkRangeCount
    ) {
      purchaseType = ASYNC_PURCHASE_CRITERIA;
      criteria.limit = this.bulkRangeCount;
      criteria.startGroup = 0;
    } else if (contactPurchaseCount > 0) {
      purchaseType = ASYNC_PURCHASE_IDS;
      request.contactIds = this.bulkPurchaseIds;
    }

    request.criteria = criteria;

    if (validationStatus) {
      request.validationStatus = validationStatus;
    } else {
      request.skipEmailValidation = true;
    }

    const postData = {
      listId,
      purchaseSource: 'CONTACT_SEARCH',
      sharedList:
        userDetail.myLeadList.find((list) => {
          return list.id === listId;
        })?.isSharedList ?? false,
      teamId: userDetail.userInfo.teamId,
      request,
      purchaseType,
    };

    const config = {
      url: URLS.purchase.async,
      method: 'POST',
      data: postData,
    };

    const response = await makeApi(config);

    if (response.status === 200 && response.data?.id) {
      this.trackPurchaseEvent(newList, 'List', 1, purchaseType);
      this.setAsyncPurchaseOrExportMsg('PURCHASE');
    } else if (
      response.data?.responseCode === 'ORC_FAIR_USAGE_REACH_DAILY_LIMIT_001'
    ) {
      showFairUsageLimitPopup();
    } else if (response.data?.responseCode === 'ORC_ASYNC_PURCHASE_005') {
      toasterState.setToastMsg(
        `You do not have enough ${
          required === 'EMAIL_AND_PHONE'
            ? 'email or phone'
            : required === 'PHONE'
              ? 'phone'
              : 'email'
        } credits`,
      );
    } else if (response.data?.responseCode === 'ORC_ASYNC_PURCHASE_006') {
      this.showSalesforceAuthError = true;
    } else {
      this.setAsyncPurchaseOrExportMsg('UNKNOWN_ERROR');
    }
    this.clearAllSelection(true);
  };

  exportAsCSV = async (data) => {
    this.setActionLoader(true);
    const { contactIds } = data;
    const postData = {
      contactIds,
      memberId: userDetail.userInfo.memberId,
    };
    const config = {
      url: URLS.exportToCSVV2,
      method: 'POST',
      data: postData,
    };
    const response = await makeApi(config);
    this.setActionLoader(false);
    if (response.data?.status === 'success') {
      this.setAsyncPurchaseOrExportMsg('CSV_EXPORT');
    } else {
      this.setAsyncPurchaseOrExportMsg('UNKNOWN_ERROR');
    }
  };

  checkSFAuth = async () => {
    const config = {
      url: URLS.SFAuth,
      method: 'GET',
    };
    const response = await makeApi(config);
    return response.status === 200;
  };
}

const advSearchState = new AdvanceSearchState();

export default advSearchState;
